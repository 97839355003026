<script setup lang="ts">
const user = useDirectusUser();
const router = useRouter();
const { logout } = useDirectusAuth();

const feedsInfo = computed(() => {
  const feeds = [
    // { label: "Accueil", to: "/", icon: "i-material-symbols-house" },
  ];
  if (user.value) {
    feeds.push(
      ...[
        { label: "Accueil", to: "/", icon: "i-material-symbols-house" },

        {
          label: "Videos",
          to: "/videos",
          icon: "i-material-symbols-play-lesson",
        },
        {
          label: "Cours",
          to: "/courses",
          icon: "i-material-symbols-collections-bookmark",
        },
        {
          label: "Module",
          to: "/modules",
          icon: "i-material-symbols-view-module-sharp",
        },
      ]
    );
  }
  return feeds;
});

const logoutAndRedirect = async () => {
  await logout();
  router.push("/");
};
</script>

<template>
  <div class="">
    <AppHeader />
    <!-- <header class="flex gap-4 justify-between mb-4">
      <nav class="flex gap-4" role="navigation">
        <UHorizontalNavigation :links="feedsInfo"></UHorizontalNavigation>
       
      </nav>
      <div class="flex flex-wrap gap-2 items-center" v-if="!user">
        <UButton label="Connexion" color="gray" to="/login" />
        <UButton
          label="Inscription"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          color="black"
          to="/signup"
        />
      </div>
      <div class="flex gap-2 items-center" v-else>
        <UButton label="Deconnexion" color="gray" @click="logoutAndRedirect" />
      </div>
    </header> -->

    <UContainer>
      <div class="mt-6 sm:mt-8 pb-6 sm:pb-8">
        <slot role="main" />
      </div>
    </UContainer>
  </div>
</template>
