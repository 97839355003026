<script setup lang="ts">
const navigation = inject<Ref<any[]>>("navigation", ref([]));
const user = useDirectusUser();

const props = defineProps<{
  stop?: boolean;
}>();
const isOpen = ref(false);

const links = computed(() =>
  user.value
    ? [
        {
          label: "Accueil",
          to: "/",
          icon: "i-material-symbols-house",
        },
        {
          label: "Videos",
          to: "/videos",
          icon: "i-material-symbols-play-lesson",
        },
        {
          label: "Cours",
          to: "/courses",
          icon: "i-material-symbols-collections-bookmark",
        },
        {
          label: "Module",
          to: "/modules",
          icon: "i-material-symbols-view-module-sharp",
        },
        // {
        //   label: "Watchlist",
        //   to: "/watchlist",
        //   icon: "i-material-symbols-view-module-sharp",
        // },
      ]
    : []
);

const transformedLinks = computed(() =>
  links.value.map((link) => ({ ...link, click: () => (isOpen.value = false) }))
);
</script>

<template>
  <header
    class="bg-white/75 dark:bg-black/75 backdrop-blur border-b border-gray-200 dark:border-gray-800 -mb-px sticky top-0 z-50"
  >
    <div
      class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl flex items-center justify-between gap-3 h-16"
    >
      <div class="lg:flex-1 flex items-center gap-1.5 md:flex-1">
        <a
          aria-current="page"
          href="/"
          class="router-link-active router-link-exact-active flex-shrink-0 font-bold text-xl text-gray-900 dark:text-white flex items-end gap-1.5"
          aria-label="JJB"
        >
          JJB
        </a>
      </div>

      <!-- <ul class="items-center gap-x-8 hidden md:flex"> -->
      <ul class="items-center gap-x-8 hidden md:flex">
        <li v-for="link in links" :key="link.label">
          <ULink
            variant="link"
            class="text-sm/6 hover:text-primary font-semibold flex items-center gap-1"
            :to="link.to"
            active-class="text-primary"
          >
            <UIcon :name="link.icon" />
            {{ link.label }}
          </ULink>
        </li>
      </ul>

      <div class="flex items-center justify-end lg:flex-1 gap-1.5 md:flex-1">
        <!-- <UColorModeButton /> -->
        <DarkMode />
        <Profile v-if="user" />
        <UButton v-if="!user" label="Connexion" color="gray" to="/login" />
        <UButton
          v-if="!user"
          label="Inscription"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          color="black"
          to="/signup"
          class="hidden lg:flex"
        />
        <UButton
          v-if="stop"
          :icon="'i-heroicons-x-mark-20-solid'"
          variant="ghost"
          color="gray"
          @click="$emit('close')"
        />
        <UButton
          v-else
          :icon="'i-heroicons-bars-3-20-solid'"
          variant="ghost"
          color="gray"
          class="md:hidden"
          @click="isOpen = true"
        />
      </div>
    </div>
  </header>

  <UModal
    v-if="!stop"
    v-model="isOpen"
    fullscreen
    class="mt-16"
    :transition="false"
    :overlay="false"
  >
    <AppHeader stop @close="isOpen = false"></AppHeader>
    <UVerticalNavigation :links="transformedLinks" class="m-2 mx-auto w-full items-enter" />
  </UModal>

  <!-- 
  <UHeader
    :links="links"
    :ui="{
      left: 'md:flex-1 ',
      right: 'md:flex-1',
      panel: {
        wrapper: 'md:hidden',
      },
      button: {
        base: 'md:hidden',
      },
    }"
  >
    <template #center>
      <UHeaderLinks class="hidden md:flex" :links="links" />
    </template>
    <template #logo> JJB </template>

    <template #right>
      <UColorModeButton />
      <Profile v-if="user" />
      <UButton v-if="!user" label="Connexion" color="gray" to="/login" />
      <UButton
        v-if="!user"
        label="Inscription"
        icon="i-heroicons-arrow-right-20-solid"
        trailing
        color="black"
        to="/signup"
        class="hidden lg:flex"
      />
    </template>
  </UHeader> -->
</template>
